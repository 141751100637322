
import axios from 'redaxios';


// const uri= "https://windrose-pellworm.de/api"
const uri= "/api/posts";
// const uri= "https://mitwebsite.de/wwp/api"

 const fillTemplate = (data) =>{
   let t=` `;
   Object.keys(data).map((key, index) =>{
     let item = data[key];
     let cl = index === 0 ? "carousel-item active" : "carousel-item";
     t += `<div class="${cl}">\n`;
     t+= `<h2 class="gb-headline">${item.headline}</h2>\n`;
     t+= `<p class="gb-comment">${item.comment}</p>\n`;
     t+= `<div class="gb-signature">${item.name}, ${formatGermanDate(item.add_date)}</div>\n`;
     t+=`</div>\n`;
   });
   return t;
 };


const allPosts = () => {
  let posts =[];
  //
  axios.get(uri)
  .then(function (response) {
    document.getElementById('gb-entries').innerHTML = fillTemplate(JSON.parse(response.data));
  })
  .catch(function (error) {
    console.log(error);
  })
  .then(function () {
    // always executed
    //  console.log("finished");
  });
 };

const submitFormGaestebuch = () =>{
  let fgb = document.getElementById('frmGb');
   fgb.addEventListener('submit', function(event) {
     if(fgb.checkValidity() === false){
       event.preventDefault();
       event.stopPropagation(); 
       fgb.classList.add('was-validated');
     }else{
       event.preventDefault();
       event.stopPropagation(); 
       let frmSuccess = document.getElementById('gb-form-success');
       let formData = new FormData(fgb)
       let fdata = {};
       for(var pair of formData.entries()) {
         fdata[pair[0]] = pair[1];
       }
       axios.post(uri, fdata)
       .then(function (response) {
          if(response.id !== null) {
           frmSuccess.style.height="110px";
           frmSuccess.style.visibility="visible"
           fgb.classList.add('was-validated');
           fgb.reset();
           fgb.remove();
         }
      })
      .catch(function (response) {
          //handle error
          console.log(response);
       }); 

     }
             
    
  });
};


 const  formatGermanDate = (edate) =>{
    // ie11 kann nicht 2019-03-24 10:11:40 umsetzen!!
    // let date = new Date(edate);
   // console.log("edate ist: ", typeof(edate));
   if (typeof(edate) !== 'undefined'){
     let year = parseInt(edate.substring(0,4));
     //Javascript getMonth rechnet von 0 bis 11
     let month = parseInt(edate.substring(5,7) -1);
     let day = parseInt(edate.substring(8,10));
     var hour = edate.substring(11,13);
     var minutes = edate.substring(14,16);
     var seconds = edate.substring(17,19);

     let myDate = new Date(year, month, day, hour, minutes, seconds);

     return myDate.toLocaleDateString("de-DE", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                   }
     );
   }   
 }  

const reloadSiteAfterModalClose = () =>{
  let modal = document.getElementById('modalGaestebuch');
  modal.addEventListener('hidden.bs.modal', () =>{
    // window.location.hash="#buch";
    // location.reload();
    allPosts();
  });
}
allPosts();
submitFormGaestebuch();
reloadSiteAfterModalClose();

