//jshint esversion:6
// jquery loaded via script tag in index.html

import Popper from "popper.js";
window.Popper = Popper;

// import './bootstrap/index.js';
import "./bootstrap/util/index.js";
import "./bootstrap/dropdown.js";
import "./bootstrap/collapse.js";
import './bootstrap/modal.js';
import './bootstrap/button.js';
import "./bootstrap/tab.js";
import "./bootstrap/scrollspy.js";
import "./bootstrap/carousel.js";
//import './marked.js';
import './gaestebuch.js';
import './lbox.js';

(function($) {
  var app = {
    navbarCollapse: function() {
      $(".navbar-nav>li>a").on("click", function() {
        $(".navbar-collapse").collapse("hide");
      });
    },
    navbarScroll: function() {
      $(".navbar a.nav-link").on("click", function(e) {
        var target = $(this).attr("href");
        // var addi = target === '#start' ? 130 : 50;
        var addi = 50;
        $("html, body").animate(
          {
            scrollTop: $(target).offset().top - addi
          },
          800,
          "swing"
        );
        e.preventDefault();
      });
    },

    toggleAufZuClass: function() {
      var $alleAufZus = $("a.aufzu");
      var $showDiv = $alleAufZus.filter(function() {
        return $($(this).attr("href")).hasClass("show");
      });
      $showDiv.addClass("auf");
      $("a.aufzu").on("click keypress", function() {
        $(this).toggleClass("auf");
      });
    }
  };

  app.navbarCollapse();
  app.navbarScroll();
  app.toggleAufZuClass();

})(jQuery);
